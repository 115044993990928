<script setup>
import { storeToRefs } from 'pinia';
import { useMessageStore } from '~/stores/messages';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Seo
useSeoMeta({
    title: staticKeys.value?.header_navigation_home_web
});

// Tracking
trackPageLoad('Welcome_Page');
</script>

<template>
    <main class="start-page-wrapper">
        <div class="start-page-container">
            <AppDownloadHeader />
            <HeroTeaser />
        </div>
    </main>
</template>

<style lang="scss">
.start-page-wrapper {
    @include fullbleed;
}

.start-page-container {
    grid-column: 1/-1;

    @include bp(large1) {
        background: $gradient1;
    }
}
</style>
